<script lang="jsx">
import { defineComponent } from "vue"
import VueTypes from "vue-types"

export const PageHeader = defineComponent({
  props: {
    title: VueTypes.string.def(""),
    routes: VueTypes.array.def([]),
  },
})

export default PageHeader
</script>

<template>
  <div class="header">
    <slot name="title">
      <h1>
        <span>{{ title || routes[routes?.length] }}</span>
      </h1>
    </slot>
    <div class="action-container">
      <slot name="buttons" />
    </div>
  </div>
  <!-- <a-breadcrumb>
    <a-breadcrumb-item v-for="(route, idx) in routes" :key="idx">
      <router-link :to="route.path">{item.breadcrumbName}</router-link>
    </a-breadcrumb-item>
  </a-breadcrumb> -->
</template>

<style scoped>
.header {
  display: flex;
  gap: 32px;
  margin-bottom: 20px;
}
h1 {
  color: #676767;
  font-size: 28px;
  font-weight: 500;
  line-height: 160%;
  margin: 0;
}
p {
  margin: 0;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 24px;
  }
}
</style>

<style>
.action-container {
  align-items: center;
  display: flex;
  gap: 16px;

  a.linkOrButton-container,
  button.linkOrButton-container {
    border-width: 1px;
  }
}
</style>