import axios from "axios";

export function apiNoAuth() {
    return axios.create({
        baseURL: 'https://rest.zoiehealth.com/api/',
        headers:{
            'ZH-API-Key': '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662',
            'Authorization': 'Basic ZWFzeWRvY0B6b2llaGVhbHRoLmNvbTp6b2llQnVzaW5lc3M=',
            'Content-Type': 'application/json'
        }});
}
export function apiNoAuthMedia() {
    return axios.create({
        baseURL: 'https://rest.zoiehealth.com/api/',
        headers:{
            'ZH-API-Key': '9e68863eaaaab20c2219cbd2630e287e8efd4f137382cfb34ae7bd067e6ae662',
            'Authorization': 'Basic ZWFzeWRvY0B6b2llaGVhbHRoLmNvbTp6b2llQnVzaW5lc3M=',
            'Content-Type': 'multipart/form-data'
        }});
}

export function apiWithToken(authToken) {
    return axios.create({
        baseURL: 'https://rest.zoiehealth.com/api/',
        headers:{
            'Authorization': 'Basic ' + authToken,
            'Content-Type': 'application/json'
        }});
}

export function apiWithTokenMedia(authToken) {
    return axios.create({
        baseURL: 'https://rest.zoiehealth.com/api/',
        headers:{
            'Authorization': 'Basic ' + authToken,
            'Content-Type': 'multipart/form-data'
        }});
}

export function apiTwilio() {
    return axios.create({
        baseURL: `https://api.twilio.com/2010-04-01/Accounts/ACd9185d7fb62a9d717b624816f6983c33/`,
        headers:{
            'Content-Type': 'application/json',
            'Authorization' : 'Basic QUNkOTE4NWQ3ZmI2MmE5ZDcxN2I2MjQ4MTZmNjk4M2MzMzpmOGUyMzQ4YTk3MmEzMzNiODBmMjI2YjdhN2IzNTg0Nw=='
        }
    });
}