import { apiNoAuth, apiWithToken, apiWithTokenMedia } from "@/vuex/modules/base";

import mutations from "./mutations"

const state = () => ({
  loading: false,
  error: null,
})

const actions = {
  async getBusinesses({ commit }) {
    commit("fetchingBusinessData")
    return apiWithToken()
      .get("business")
      .then(response => {
        return response.data.value;
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
    async getBySlug({ commit }, slug) {
    return apiNoAuth().get("business/branding/" + slug)
    .then(response => {
      return response.data.value;
      })
      .catch(err => {
        commit('eventVisibleErr', err);
        return err.response.data;
      });

    },
    async getById({ commit }, id) {
    return apiNoAuth().get("business/" + id)
    .then(response => {
      return response.data.value;
      })
      .catch(err => {
        commit('eventVisibleErr', err);
        return err.response.data;
      });

    },
    async getTeamMember({ commit }, payload) {
      let token = localStorage.getItem('authToken');
      let businessId = localStorage.getItem('businessId');
      return apiWithToken(token)
      .get( "Business/" + businessId + "/team/" + payload.memberId)
      .then(response => {
        return response.data.value;
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async getAdmin({ commit }, payload) {
    let token = localStorage.getItem("authToken")
    return apiWithToken(token)
      .get("UserAuth/" + payload.userId)
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async getAdmins({ commit }) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
    .get( "business/" + businessId + "/admin")
    .then(response => {
      return response.data.value;
    })
    .catch(err => {
      commit('eventVisibleErr', err);
    });

  }, async reviewedSickNote({ dispatch }, id) { // eslint-disable-line no-unused-vars
        let token = localStorage.getItem('authToken');
        let businessId = localStorage.getItem('businessId');

        return apiWithToken(token)
            .post('business/' + businessId + '/sicknote/' + id + '/reviewed')
            .then((response) => {
                return response.data.value
            })
            .catch(err => {
                return err
            })
    },
    getSickNote({dispatch}, id) { // eslint-disable-line no-unused-vars
        let token = localStorage.getItem('authToken');
        let businessId = localStorage.getItem('businessId');
        return apiWithToken(token)
            .get('business/' + businessId + '/sicknote/' + id)
            .then((response) => {
            return response.data.value
        })

    },
    async getSickNotes({ commit }) {
      let token = localStorage.getItem('authToken');
      let businessId = localStorage.getItem('businessId');
      return apiWithToken(token)
      .get( "business/" + businessId + "/sicknotes")
      .then(response => {
        return response.data.value;
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async updateAdmin({ commit }, data) {
    let token = localStorage.getItem("authToken")
    commit("updatingBusinessData")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
        .put("UserAuth/update-profile/" + data.userId, data.body)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
          commit("eventVisibleErr", err)
        })
    })
  },
    async getNewSickNotesCount({ commit }) {
      let token = localStorage.getItem('authToken');
      let businessId = localStorage.getItem('businessId');
      return apiWithToken(token)
      .get( "business/" + businessId + "/notes/new")
      .then(response => {
        return response.data.value;
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async updateMember({ commit }, data) {
    let businessId = localStorage.getItem("businessId")
    let token = localStorage.getItem("authToken")
    commit("updatingBusinessData")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
        .put("business/" + businessId + "/member/" + data.memberId, data.body)
        .then((response) => {
            resolve(response.data.value);
        })
        .catch((err) => {
          reject(err)
        })
      })
    },
    async cancel(_) { // eslint-disable-line no-unused-vars
      let token = localStorage.getItem('authToken');
      let businessId = localStorage.getItem('businessId');
        return new Promise((resolve, reject) => {
            apiWithToken(token).delete('business/' + businessId)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => { reject(err) })
        })
  },
  async addMember({}, payload) {
    // eslint-disable-line no-unused-vars
    return apiNoAuth()
      .post("business/" + payload.businessId + "/member", payload)
      .then((response) => {
        return response.data.value.value
      })
      .catch((err) => {
        return err.response.data
      })
  },
  async addDependant({}, payload) {
    // eslint-disable-line no-unused-vars
    return apiNoAuth()
      .post("business/" + payload.businessId + "/member/" + payload.principalId + '/dependant', payload)
      .then((response) => {
        return response.data.value.value
      })
      .catch((err) => {
        return err.response.data
      })
  },
  async addMemberAdmin({ commit }, payload) {
    let token = localStorage.getItem("authToken")
    return apiWithToken(token)
      .post("business/" + payload.businessId + "/member", payload)
      .then((response) => {
        return response.data.value.value
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
        return err.response.data
      })
  },
    async addTeam({ commit }, data) {
      commit('setLoading', true);
      let token = localStorage.getItem('authToken');
      let businessId = localStorage.getItem('businessId');
      const formData = new FormData();
      formData.append('file', data.file);
      return apiWithToken(token ).post( 'business/' + businessId + "/team", formData)
        .then((res) => {
          if (res.status === 200) {
            commit('setLoading', false);
            // stringify response data val so that res = 0 and res = "Could not read" are not treated the same
            return { data: JSON.stringify(res.data.value), ...res };
          }
          throw { response: { data: { title: "Failed to upload file", message: res.errors[0]} } };
        }).catch((err) => {
          commit('setLoading', false);
          return err.response.data;
        });
    },
    async approveMember({ commit }, data) {
    commit('fetchingBusinessData');
    let token = localStorage.getItem('authToken');
    let businessId = localStorage.getItem('businessId');
    return apiWithToken(token)
      .post("business/" + businessId + "/team/" + data.memberId + "/approve")
      .then((response) => {
        return response
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async deactivateAdmin({}, data) {
    // eslint-disable-line no-unused-vars
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .delete("business/" + businessId + "/admin/" + data.usershipId)
      .then((response) => {
        return response
      })
  },
  async deactivateMember({ commit }, data) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .post("business/" + businessId + "/team/" + data.memberId + "/deactivate")
      .then((response) => {
        return response
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async inviteTeam({ commit }) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .post("business/" + businessId + "/team/invite")
      .then((response) => {
        return response
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async inviteMember({ commit }, data) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    commit("setLoading", true)
    return apiWithToken(token)
      .post("business/" + businessId + "/team/" + data.memberId + "/invite")
      .then((response) => {
        commit("setLoading", false)
        return response
      })
      .catch((err) => {
        commit("setLoading", false)
        commit("eventVisibleErr", err)
      })
  },
  async rejectMember({ commit }, data) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .post("business/" + businessId + "/team/" + data.memberId + "/reject")
      .then((response) => {
        return response
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async getBusinessTeam({ commit }) {
    let payload = {
      businessId: localStorage.getItem("businessId"),
      token: localStorage.getItem("authToken"),
    }
    return apiWithToken(payload.token)
      .get( "business/" + payload.businessId + "/team")
      .then(response => {
        return response.data.value;
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async addBusinessTeam({ commit }) {
    let payload = {
      businessId: localStorage.getItem("businessId"),
      token: localStorage.getItem("authToken"),
    }
    return apiWithToken(payload.token)
      .get( "business/" + payload.businessId + "/team")
      .then(response => {
          console.log(response);
        return response.data.value;
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
    update({ commit }, data) {
      let token = localStorage.getItem('authToken');
        let businessId = localStorage.getItem('businessId');
        return new Promise((resolve, reject) => {
            apiWithToken(token).put('business/' + businessId, data)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => {
                    reject(err);
                    commit('eventVisibleErr', err);
                })
        })
    },
    getTeamAdmin({ state }) { // eslint-disable-line no-unused-vars
        let businessId = localStorage.getItem('businessId');
        let token = localStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            apiWithToken(token).get('business/' + businessId + '/admin')
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => { reject(err) })
        })
    },
    inviteAdmin({ state }, data) { // eslint-disable-line no-unused-vars
        let businessId = localStorage.getItem('businessId');
        let token = localStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            apiWithToken(token)
                .post('business/' + businessId + '/member-admin', data)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data.value)
                    } else {
                        reject(response)
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err) })
        })
  },

  teamCount({}) {
    // eslint-disable-line no-unused-vars
    let businessId = localStorage.getItem("businessId")
    let token = localStorage.getItem("authToken")

        return new Promise((resolve, reject) => {
            apiWithToken(token).get(`business/${businessId}/team/count`)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => { reject(err) })
        })
  },
  consultCount({}) {
    // eslint-disable-line no-unused-vars
    let businessId = localStorage.getItem("businessId")
    let token = localStorage.getItem("authToken")

        return new Promise((resolve, reject) => {
            apiWithToken(token).get(`business/${businessId}/consult/count`)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => { reject(err) })
        })
  },
  textCount({}) {
    // eslint-disable-line no-unused-vars
    let businessId = localStorage.getItem("businessId")
    let token = localStorage.getItem("authToken")

        return new Promise((resolve, reject) => {
            apiWithToken(token).get(`business/${businessId}/text/count`)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => { reject(err) })
        })
  },
  noteCount({}) {
    // eslint-disable-line no-unused-vars
    let businessId = localStorage.getItem("businessId")
    let token = localStorage.getItem("authToken")

        return new Promise((resolve, reject) => {
            apiWithToken(token).get(`business/${businessId}/notes/count`)
                .then((response) => {
                    resolve(response.data.value)
                })
                .catch(err => { reject(err) })
        })
    },
    uploadFile({ commit }, req) { // eslint-disable-line no-unused-vars
      let token = localStorage.getItem('authToken');
      const formData = new FormData();
      formData.append('file', req.file);
      return apiWithTokenMedia( token ).post(`Util/upload/${req.folder}`, formData)
          .then((res) => {
              if (res.status === 200) {
                  return res.data.value;
              }
              throw new Error('Failed to upload file.');
          })
          .catch((err) => {
              return err.response.data;
          });
  },
  dashboard({}, payload) {
    // eslint-disable-line no-unused-vars
    let token = localStorage.getItem("authToken")
    return apiWithToken(token)
    .get(`business/${payload.businessId}/metabase/${payload.dashboardId}`)
    .then((response) => {
      return response.data.value
    })
    .catch((error) => {
      return error.response.data
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
