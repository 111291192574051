import {
  Alert,
  AutoComplete,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Calendar,
  Card,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  Comment,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Modal,
  PageHeader,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Rate,
  Result,
  Row,
  Select,
  Skeleton,
  Slider,
  Space,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Tree,
  TreeSelect,
  Upload,
  message,
} from "ant-design-vue"

import app from "../../config/configApp"

// app.prototype.$message = message;
app.config.globalProperties.$message = message

app.use(Alert)
app.use(AutoComplete)
app.use(Avatar)
app.use(Badge)
app.use(Breadcrumb)
app.use(Button)
app.use(Calendar)
app.use(Card)
app.use(Carousel)
app.use(Cascader)
app.use(Checkbox)
app.use(Col)
app.use(Collapse)
app.use(Comment)
app.use(DatePicker)
app.use(Divider)
app.use(Drawer)
app.use(Dropdown)
app.use(Empty)
app.use(Form)
app.use(Icon)
app.use(Input)
app.use(InputNumber)
app.use(Layout)
app.use(List)
app.use(Menu)
app.use(Modal)
app.use(PageHeader)
app.use(Pagination)
app.use(Popconfirm)
app.use(Popover)
app.use(Progress)
app.use(Radio)
app.use(Rate)
app.use(Result)
app.use(Row)
app.use(Select)
app.use(Skeleton)
app.use(Slider)
app.use(Space)
app.use(Spin)
app.use(Statistic)
app.use(Steps)
app.use(Switch)
app.use(Table)
app.use(Tabs)
app.use(Tag)
app.use(Timeline)
app.use(TimePicker)
app.use(Tooltip)
app.use(Tree)
app.use(TreeSelect)
app.use(Upload)
