import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import app from "../../config/configApp"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"

library.add(far)
library.add(fas)

app.component("FontAwesomeIcon", FontAwesomeIcon)
