import { apiWithToken } from "@/vuex/modules/base"

let providerId = localStorage.getItem('providerId');
let token = localStorage.getItem('authToken');

const state = () => ({
    loading: false,
    error: null,
});

const actions = {
    async dashboard({}, payload) {
        let token = localStorage.getItem("authToken")
        return apiWithToken(token)
        .get(`provider/${providerId}/metabase/${payload.dashboardId}`)
        .then((response) => {
            return response.data.value
        })
        .catch((error) => {
            return error.response.data
        })
    },
    async submitRecord({commit}, payload) {
        return apiWithToken(token)
            .post(`provider/${providerId}/event/${payload.eventId}/record`, payload.data)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            })
    },
    async updateRecord({commit}, payload) {
        return apiWithToken(token)
            .post(`provider/${providerId}/record/${payload.recordId}`, payload.data)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            })
    },async uploadConsultRecords({commit}, payload) {
        return apiWithToken(token)
            .post(`provider/${providerId}/consult/${payload.consultId}/upload`, payload.data)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            })
    },async uploadWellnessConsultRecords({commit}, payload) {
        return apiWithToken(token)
            .post(`provider/${providerId}/event/${payload.eventId}/member/${payload.teamId}`, payload.data)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            })
    },
    async getConsults({commit}) {
        return apiWithToken(token)
            .get(`provider/${providerId}/consults`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    }, async getConsult({commit}, consultId) {
        return apiWithToken(token)
            .get(`provider/${providerId}/consult/${consultId}`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },
    async getTexts({commit}) {
        return apiWithToken(token)
            .get(`provider/${providerId}/texts`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },async getTextSession({commit}, sessionId) {
        return apiWithToken(token)
            .get(`provider/${providerId}/texts/${sessionId}`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },
    async getEvents({commit}) {
        return apiWithToken(token)
            .get(`provider/${providerId}/events`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },
    async getUpcomingEventCount({commit}) {
        return apiWithToken(token)
            .get(`provider/${providerId}/events/upcoming/count`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },async getUpcomingConsultCount({commit}) {
        return apiWithToken(token)
            .get(`provider/${providerId}/consults/upcoming/count`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },
    async getEvent({commit}, eventId) {
        return apiWithToken(token)
            .get(`provider/${providerId}/event/${eventId}`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },
    async getTeam({commit}, eventId) {
        return apiWithToken(token)
            .get(`provider/${providerId}/event/${eventId}/members`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });

    },
    async getTeamMember({commit}, payload) {
        return apiWithToken(token)
            .get(`provider/${providerId}/event/${payload.eventId}/member/${payload.teamId}`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });
    },
    async getRecord({commit}, recordId) {
        return apiWithToken(token)
            .get(`provider/${providerId}/record/${recordId}`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });
    }, async getServices({commit}) {
        return apiWithToken(token)
            .get(`provider/services`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });
    },
    async getCategories({ commit }) {
        return apiWithToken(token)
            .get(`provider/categories`)
            .then(response => {
                return response.data.value;
            })
            .catch(err => {
                commit('eventVisibleErr', err);
            });
    },
    async rescheduleConsult({ commit }, payload) {
        return apiWithToken(token)
            .put(`provider/${payload.providerId}/consult/${payload.consultId}/reschedule`, payload.data)
            .then(response => {
                return response.data.value
            })
            .catch(err => {
                commit('eventVisibleErr', err)
            });
    },
    async updateMember({ commit }, payload) {
        return apiWithToken(token)
          .put(`provider/${providerId}/event/${payload.eventId}/member`, payload.data)
          .then((response) => {
                return response.payload.value
            })
            .catch((err) => {
                commit('eventVisibleErr', err)
            })
        },
}

export default {
    namespaced: true,
    actions,
    state,
};
