import auth from './modules/authentication/axios/actionCreator';
import business from './modules/business/actionCreator';
import businessOperations from './modules/businessOperations/actionCreator';
import { createStore } from 'vuex';
import gallery from './modules/gallery';
import notifications from "./modules/notifications/actionCreator"
import notify from "./modules/notify/actionCreator";
import payfast from './modules/payfast/actionCreator';
import provider from './modules/provider/actionCreator';
import chat from "./modules/chat/actionCreator";
import themeLayout from './modules/themeLayout/actionCreator';

export default createStore({
  modules: {
    auth: auth,
    business: business,
    businessOperations: businessOperations,
    chat: chat,
    gallery: gallery,
    notifications: notifications,
    notify: notify,
    payfast: payfast,
    provider: provider,
    themeLayout: themeLayout,
  },
})
