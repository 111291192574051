import {apiNoAuth, apiWithToken} from "@/vuex/modules/base";

import Cookies from "js-cookie"

const MY_EASYDOC_ID = 1120

const state = {
    businessId: null,
    user: null,
    usership: null,
    provider: null,
    redirectPath: null,
    registrationStep: null,
    token: null,
    role: null,
    loading: false,
    error: null,
};

const mutations = {
  setUser(state, user) {
    if (user) {
      state.user = user;
    }
    },
    setRedirectPath(state, path) {
        state.redirectPath = path;
    },
    setBusinessId(state, id) {
        state.businessId = id;
        localStorage.setItem('businessId', id);
        state.role = "business";
    },
    setUsership(state, ship) {
      state.usership = ship;
      state.role = "business";
    },
    setProvider(state, provider) {
        state.provider = provider;
        localStorage.setItem('providerId', provider.id);
        state.role = "provider";
    },
    setRegistrationStep(state, step) {
        state.registrationStep = step;
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('authToken', token);
    },
    clearRedirectPath: (state) => state.redirectPath = null,

    reset(state) {
        state.user = null;
        state.token = null;
        state.businessId = null;
        state.registrationStep = null;
        state.role = null;
        state.usership = null;
        localStorage.removeItem('authToken');
        localStorage.removeItem('businessId');
        localStorage.removeItem('providerId');
    },
    setLoading: (state, busy) => state.loading = busy,
};

const getters = {
    isAuthenticated: () => {
        return ( !!localStorage.getItem('authToken') && localStorage.getItem('businessId') > 0);
    },
    isAuthenticatedProvider: () => {
        return ( !!localStorage.getItem('authToken') && !!localStorage.getItem('providerId')> 0);
    },
    getAuthToken: (state) => state.token,
    getUser: (state) => state.user,
    getProvider: (state) => state.provider,
    getUsername: (state) => state.user?.name,
    getUsership: (state) => state.usership,
    getRedirectPath: (state) => state.redirectPath,
    getUserRole: (state) => state.role,
};

const actions = {
    async login({ commit, dispatch }, payload) { // eslint-disable-line no-unused-vars
        try {
            commit('setLoading', true);
            const base64String = btoa(`${payload.email}:${payload.password}`);
            const response = await apiWithToken(base64String).get("userAuth/authorize-business");
            let user = response.data;
            console.log(user);
            commit('setUser', user);
            commit('setToken', base64String);
            if (user.userships && user.userships.length > 0) {
                commit('setUsership', user.userships[0]);
                commit('setBusinessId', user.userships[0].companyId);
            } else if (user.role === 2 && user.provider) {
                commit('setProvider', user.provider);
            }
            commit('setLoading', false);
            return user;
        } catch (err) {
            console.log(err);
            commit('setLoading', false);
            return err.response;
        }
    },
    async reloadSession({ commit }) { // eslint-disable-line no-unused-vars
        if (!localStorage.getItem('authToken')) return;
        try {
            const token = localStorage.getItem('authToken');
            const response = await apiWithToken(token).get("userAuth/authorize-business");
            let user = response.data;
            commit('setUser', user);
            commit('setToken', token);
            if (localStorage.getItem('businessId')) {
                let usership = user.userships.find(ship => ship.companyId === parseInt(localStorage.getItem('businessId')));
                if (usership) {
                    commit('setUsership', usership);
                } else {
                    commit('setUsership', user.userships[0]);
                    commit('setBusinessId', user.userships[0].companyId);
                }
            } else if (user.userships && user.userships.length > 0) {
                commit('setUsership', user.userships[0]);
                commit('setBusinessId', user.userships[0].companyId);
            } else if (user.role === 2 && user.provider) {
                commit('setProvider', user.provider);
            }
            return response.data;
        } catch (err) {
            console.log(err);
            return err
        }

    },
    async forgotPassword({ commit }, payload) { // eslint-disable-line no-unused-vars
    try {
      const response = await apiNoAuth().post(
        "passwordReset/business/" + payload.email,
      )
      return response.data
    } catch (err) {
      console.log(err)
      return err
    }
  },
  async switchBusiness({ dispatch, commit }, payload) {
    try {
      commit("setBusinessId", payload.businessId)
      await dispatch("reloadSession")
    } catch (err) {
      console.log(err)
      return err
    }
  },
  async updateUserPassword(context, payload) {
    try {
      const token = localStorage.getItem("authToken")
      const response = await apiWithToken(token).post(
        `passwordReset/update-password/${payload.userId}/${payload.userEmail}/${payload.resetGuid}/${payload.newPassword}`,
      )
      return response.data
    } catch (err) {
      console.log(err)
      return err
    }
  },
  async resetPassword(context, payload) {
    return await apiNoAuth().post(`passwordReset/business/`, payload)
  },

  async acceptInvite(context, payload) {
    return await apiNoAuth().post(`passwordReset/business/accept/`, payload)
  },
  async logOut({ commit }) {
    try {
      commit("reset")
    } catch (err) {
      commit("logoutErr", err)
    }
  },

  async sendOtp({}, data) {
    try {
      const response = await apiNoAuth().post("Util/ZoieOtp", data)
      return response.data
    } catch (err) {
      console.log(err)
      return err
    }
  },

  async verifyOtp({}, data) {
    const response = await apiNoAuth().post("Util/ZoieVerifyOtp", data)
    console.log(response)
    return response.data.success
  },

  async createBusiness({ commit }, data) {
    try {
      const response = await apiNoAuth().post("Business", data)
      if (response.data) {
        commit("setBusinessId", response.data.id)
        commit("setRegistrationStep", 1)
        return response.data
      }
    } catch (err) {
      console.log(err)
      return err.response ? err.response.data : err
    }
  },

  async registerMember({}, data) {
    try {
      const response = await apiNoAuth().post("myeasydoc", data)
      if (response.data) {
        return response.data
      }
    } catch (err) {
      console.log(err)
      return err.response ? err.response.data : err
    }
  },

  async getMyEasyDocMember({}, data) {
    try {
      const response = await apiNoAuth().get(
        "Business/" + MY_EASYDOC_ID + "/team/" + data.memberId,
      )
      if (response.data) {
        return response.data.value
      }
    } catch (err) {
      console.log(err)
      return err.response ? err.response.data : err
    }
  },

  async updateBusiness({}, data) {
    try {
      const response = await apiNoAuth().put("business/" + data.id, data)
      if (response.data) {
        return response.data.value
      }
    } catch (err) {
      console.log(err)
      return err.response ? err.response.data : err
    }
  },

  async updateBusinessSubscription({ commit }, data) {
    try {
      const response = await apiNoAuth().put(
        "business/" + data.id + "/subscription",
        data,
      )
      if (response.data) {
        commit("setRegistrationStep", 3)
        return response.data
      }
    } catch (err) {
      console.log(err)
      return err.response ? err.response.data : err
    }
  },

  registrationComplete({ commit }, data) {
    Cookies.set("user", JSON.stringify(data))
    commit("login", true)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
