import { apiWithToken } from "@/vuex/modules/base"

const state = () => ({
  loading: false,
  error: null,
})

const actions = {
  approveBenefitRequest({}, payload) {
    // eslint-disable-line no-unused-vars
    let token = localStorage.getItem("authToken")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
      .post(`business/benefit/request/${payload.requestId}/approve`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.value)
        } else {
          reject(response)
        }
      })
    .catch((err) => {
      console.log(err);
      reject(err) })
    })
  },
  archiveBenefitRequest({}, payload) {
    // eslint-disable-line no-unused-vars
    let token = localStorage.getItem("authToken")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
      .post(`business/benefit/request/${payload.requestId}/archive`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.value)
        } else {
          reject(response)
        }
      })
    .catch((err) => {
      console.log(err);
      reject(err) })
    })
  },
  cancelBenefitRequest({}, payload) {
    // eslint-disable-line no-unused-vars
    let token = localStorage.getItem("authToken")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
      .post(`business/benefit/request/${payload.requestId}/cancel`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.value)
        } else {
          reject(response)
        }
      })
    .catch((err) => {
      console.log(err);
      reject(err) })
    })
  },
  declineBenefitRequest({}, payload) {
    // eslint-disable-line no-unused-vars
    let token = localStorage.getItem("authToken")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
      .post(`business/benefit/request/${payload.requestId}/decline`, payload.data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.value)
        } else {
          reject(response)
        }
      })
    .catch((err) => {
      console.log(err);
      reject(err) })
    })
  },
  getBenefitRequests() {
    let businessId = localStorage.getItem("businessId")
    let token = localStorage.getItem("authToken")
    return new Promise((resolve, reject) => {
      apiWithToken(token)
      .get("business/" + businessId + "/benefit/requests")
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.value)
        } else {
          reject(response)
        }
      })
    .catch((err) => {
      console.log(err);
      reject(err) })
    })
  },
  getNewRequestsCount({ commit }) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .get("business/" + businessId + "/benefits/new")
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
