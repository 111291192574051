import {apiTwilio, apiWithToken} from "@/vuex/modules/base"

let token = localStorage.getItem('authToken');
let providerId = 1;

const state = () => ({
    loading: false,
    error: null,
});
const actions = {
    getMessagesByQuery({ dispatch }, query) { // eslint-disable-line no-unused-vars
        console.log(query)
        return new Promise((resolve, reject) => {
            apiTwilio()
                .get('Messages.json?PageSize=100&'+query)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    sendMessage({ dispatch }, payload) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            apiWithToken(payload.authToken)
                .post('messaging/sendWhatsappMessageToUser', null, {params: payload.data})
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    requestChat({ dispatch }, tsId) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            apiWithToken(token)
                .post(`provider/${providerId}/text/${tsId}/request`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    medLm({ dispatch }, thread) { // eslint-disable-line no-unused-vars
        return new Promise((resolve, reject) => {
            apiWithToken(token)
                .post(`gemini/medlm-chat`, thread)
                .then((response) => {
                    resolve(response.data.value);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export default {
    namespaced: true,
    actions,
    state,
};