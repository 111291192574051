import { apiWithToken } from "@/vuex/modules/base"

const state = () => ({
  loading: false,
  error: null,
})

const actions = {
  async checkForUnread({ commit }) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .get(`business/${businessId}/transactionLogs/new`)
      .then((response) => {
        return response.data.value
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async getNotifications({ commit }, payload = {}) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .get(
        `business/${businessId}/transactionLogs/${payload.transactionStatus}`,
      )
      .then((response) => {
        return response.data.value
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
  async markAsRead({ commit }, payload = {}) {
    let token = localStorage.getItem("authToken")
    let businessId = localStorage.getItem("businessId")
    return apiWithToken(token)
      .post(
        `business/${businessId}/transactionLogs/viewed/${payload.transactionId}`,
      )
      .then(() => {
        return true
      })
      .catch((err) => {
        commit("eventVisibleErr", err)
      })
  },
}

export default {
  namespaced: true,
  state,
  actions,
}
